import { MAIN_URL } from "@kalecard/common";
import { Link, useLocation } from "react-router-dom";

interface SignupFooterProps {
  isLogin: boolean;
  showLogin?: boolean;
}

export default function SignupFooter({
  isLogin,
  showLogin = true,
}: SignupFooterProps) {
  const locationState: any = useLocation().state;
  return !isLogin ? (
    <>
      {showLogin && (
        <div className="mt-2 text-xs">
          Already have an account?{" "}
          <Link
            className="font-semibold text-kale-green-500 underline hover:text-kale-green-400"
            to={{
              pathname: "/login",
              state: locationState?.from ? { from: locationState?.from } : null,
            }}
          >
            Login
          </Link>
        </div>
      )}
      <div className="mt-2 text-xs">
        By signing up you agree to our{" "}
        <a
          href={`${MAIN_URL}/terms`}
          className="font-semibold text-kale-green-500 underline hover:text-kale-green-400"
          target="_blank"
          rel="noreferrer"
        >
          terms of service
        </a>{" "}
        and{" "}
        <a
          href={`${MAIN_URL}/legal`}
          className="font-semibold text-kale-green-500 underline hover:text-kale-green-400"
          target="_blank"
          rel="noreferrer"
        >
          privacy policy
        </a>
        .
      </div>
    </>
  ) : (
    <>
      <div className="mt-2 text-xs">
        Don't have an account yet?{" "}
        <Link
          className="font-semibold text-kale-green-500 underline hover:text-kale-green-400"
          to={{
            pathname: "/signup",
            state: locationState?.from ? { from: locationState?.from } : null,
          }}
        >
          Sign up
        </Link>
      </div>
      <div className="mt-2 text-xs">
        By logging in you agree to our{" "}
        <a
          href={`${MAIN_URL}/terms`}
          className="font-semibold text-kale-green-500 underline hover:text-kale-green-400"
          target="_blank"
          rel="noreferrer"
        >
          terms of service
        </a>{" "}
        and{" "}
        <a
          href={`${MAIN_URL}/legal`}
          className="font-semibold text-kale-green-500 underline hover:text-kale-green-400"
          target="_blank"
          rel="noreferrer"
        >
          privacy policy
        </a>
        .
      </div>
    </>
  );
}
