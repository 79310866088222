import { useMutation } from "@apollo/client";
import { Loading, MAIN_URL } from "@kalecard/common";
import { useContext, useEffect } from "react";
import { useCookies } from "react-cookie";
import { auth } from "../config/firebase";
import { CREATOR_SESSION } from "../graphql/mutations";
import { UserContext } from "../providers/UserProvider";
import { useHistory } from "react-router-dom";

export default function HomePage() {
  let history = useHistory();
  const { user } = useContext(UserContext);
  const [cookies] = useCookies(["session_token"]);
  const [creatorSession] = useMutation(CREATOR_SESSION);

  const checkUser = async () => {
    try {
      const result = await creatorSession({
        context: {
          headers: {
            authorization: cookies.session_token
              ? `Bearer ${cookies.session_token}`
              : "",
          },
        },
      });
      console.log("custom token result", result);
      auth
        .signInWithCustomToken(result.data.creatorSession)
        .then((user) => {
          console.log("user is signed in now", user);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      // Most likely means the session_token is invalid
      console.error(error);
      history.push("/login");
    }
  };

  useEffect(() => {
    if (!user) {
      if (cookies.session_token) {
        checkUser();
      } else {
        history.push("/login");
      }
    }
  }, [user, cookies]);
  return (
    <>
      <Loading />
    </>
  );
}
