import { useState } from "react";
import { ActionType, logAction } from "@kalecard/common";
import { XMarkIcon } from "@heroicons/react/24/solid";
import KaleLogo from "../images/kale-logo.svg";
import { isAndroid, isMobile } from "react-device-detect";

const AppBanner = () => {
  const [isOpen, setIsOpen] = useState(true);
  const link = isAndroid ?
    "https://play.google.com/store/apps/details?id=com.kalecard.kale" :
    "https://apps.apple.com/us/app/kale-creator-rewards/id1672660475";
  const subText = isAndroid ?
    "On the Play Store" :
    "On the App Store";

  const handleClose = () => {
    setIsOpen(false);
  }

  const handleLinkClick = () => {
    logAction(ActionType.CLICK, "ChallengeComponent", {
      action_name: "app_banner_click",
      platform: isAndroid ? "Android" : "iOS",
    });
  }

  if (!isOpen || !isMobile) {
    return null;
  }

  return (
    <div className="flex flex-row justify-between items-center bg-gray-100 border-b border-gray-200 py-2 px-4">
      <div className="flex flex-row items-center">
        <XMarkIcon
          onClick={handleClose}
          className="h-4 w-4 cursor-pointer text-gray-400"
        />
        <div className="h-14 w-14 rounded-xl bg-kale-mint-300 mx-2">
          <img className="" src={KaleLogo} alt="Kale" />
        </div>
        <div>
          <div className="font-bold text-gray-600">
            Kale - Creator Rewards
          </div>
          <div className="text-xs text-gray-500">
            {`FREE - ${subText}`}
          </div>
        </div>
      </div>
      <a href={link} className="text-blue-500" onClick={handleLinkClick}>View</a>
    </div>
  );
}

export default AppBanner;