import { useHistory, useLocation } from "react-router";
import { useEffect } from "react";
import { Loading } from "@kalecard/common";

export default function FacebookOAuthPage() {
  const { hash } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(hash);
    if (
      params.has("long_lived_token") ||
      params.has("access_token") ||
      params.has("#access_token") ||
      params.has("#long_lived_token")
    ) {
      const accessToken =
        params.get("long_lived_token") ||
        params.get("access_token") ||
        params.get("#access_token") ||
        params.get("#long_lived_token");
      window.location.href = `kale://connect/facebook?access_token=${accessToken}`;

      // In case it fails to redirect to the app.
      history.replace({
        search: "",
      });
    }
  }, [hash]);
  return (
    <div className="flex w-full flex-col px-4">
      <h2 className="text-center text-xl">
        Just a few more seconds while we finish the connection with Instagram.
      </h2>
      <Loading />
    </div>
  );
}
