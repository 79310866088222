import { useMutation } from "@apollo/client";
import { useCallback, useEffect } from "react";
import {
  PlaidLinkOnEvent,
  PlaidLinkOnEventMetadata,
  PlaidLinkStableEvent,
  usePlaidLink,
} from "react-plaid-link";
import { useHistory } from "react-router-dom";
import { auth } from "../config/firebase";
import { PLAID_EXCHANGE_PUBLIC_TOKEN } from "../graphql/mutations";
import { localStorageService, LocalStorageKey } from "@kalecard/common";
import {
  ApiType,
  logApiError,
  logApiEvent,
  logApiSuccess,
  logView,
} from "@kalecard/common";
import { Loading } from "@kalecard/common";

const COMPONENT_NAME = "PlaidOAuthLink";

export default function PlaidOAuthLink() {
  const history = useHistory();
  const [plaidExchangePublicToken] = useMutation(PLAID_EXCHANGE_PUBLIC_TOKEN);
  // The Link token from the first Link initialization
  const linkToken = localStorageService.getItem(
    LocalStorageKey.PLAID_LINK_TOKEN
  );

  const onSuccess = useCallback(async (public_token: string) => {
    console.log(public_token);
    const tokenId = await auth.currentUser.getIdToken();
    try {
      const result = await plaidExchangePublicToken({
        variables: {
          publicToken: public_token,
        },
        context: {
          headers: {
            authorization: tokenId ? `Bearer ${tokenId}` : "",
          },
        },
      });
      logApiSuccess(ApiType.PLAID, "plaidExchangePublicToken", COMPONENT_NAME);
      console.log(result);
      history.push("/");
    } catch (err) {
      logApiError(
        ApiType.PLAID,
        "plaidExchangePublicToken",
        COMPONENT_NAME,
        err?.message
      );
      console.error(err);
    } finally {
    }
  }, []);

  // const onExit = (err, metatdata) => {
  //   // handle error...
  //   // console.error("ERRORRR", err, metatdata);
  //   logApiError("plaid_connect", ApiType.PLAID, "plaid_oauth_exit", "PlaidOAuthLink", err, metatdata);
  //   history.push("/connect/plaid");
  // };

  const onEvent = useCallback<PlaidLinkOnEvent>(
    (
      eventName: PlaidLinkStableEvent | string,
      metadata: PlaidLinkOnEventMetadata
    ) => {
      logApiEvent(ApiType.PLAID, eventName, COMPONENT_NAME, metadata);
    },
    []
  );

  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken!,
    receivedRedirectUri: window.location.href, //the redirect URI with an OAuth state ID parameter
    onSuccess: onSuccess,
    // onExit,
    onEvent,
  };

  const { open, ready, error } = usePlaidLink(config);

  // automatically reinitialize Link
  useEffect(() => {
    if (ready) {
      logView(COMPONENT_NAME);
      open();
    }
  }, [ready, open]);

  if (error) {
    return (
      <div>
        <p>{error.message}</p>
      </div>
    );
  } else {
    return (
      <>
        <h2 className="text-center text-xl">
          Just a few more seconds while we complete your credit card connection.
          🤖
        </h2>
        <Loading />
      </>
    );
  }
}
