import { useApolloClient } from "@apollo/client";
import { COOKIE_DOMAIN, Creator } from "@kalecard/common";
import { createContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import client from "../config/apolloClient";
import { analytics, auth } from "../config/firebase";
import { CREATOR } from "../graphql/queries";
import { localStorageService, LocalStorageKey } from "@kalecard/common";
import { logUser } from "@kalecard/common";

export const UserContext = createContext<{
  user: firebase.default.User | null;
  creator: Creator | null;
}>({
  user: null,
  creator: null,
});

const UserProvider = ({ children }: any) => {
  const [user, setUser] = useState<firebase.default.User | null>(undefined);
  const [creator, setCreator] = useState<Creator | null>(null);
  const apolloClient = useApolloClient();
  const [__, _, removeCookie] = useCookies(["session_token"]);

  useEffect(() => {
    const unsub = auth.onAuthStateChanged(async (userAuth) => {
      // TODO: Get more data if we need it about the user. This happens when we
      // find the logged in user.

      // Reset the store so previously logged in user doesn't get the old queries
      client.resetStore();

      if (userAuth) {
        logUser(userAuth.uid, {
          email: userAuth.email,
          phone: userAuth.phoneNumber,
        });
        localStorageService.setItem(LocalStorageKey.AUTHENTICATED, "true");
        const tokenId = await userAuth.getIdToken();
        try {
          const result = await apolloClient.query({
            query: CREATOR,
            fetchPolicy: "network-only",
            variables: {
              userId: userAuth.uid,
            },
            context: {
              headers: {
                authorization: tokenId ? `Bearer ${tokenId}` : "",
              },
            },
          });
          setCreator(result.data.creator);
          analytics.setUserProperties({ creator_id: userAuth.uid });
        } catch (error) {
          console.error(error);
        }
      } else {
        localStorageService.setItem(LocalStorageKey.AUTHENTICATED, "false");
        removeCookie("session_token", {
          path: "/",
          domain: COOKIE_DOMAIN,
        });
      }
      setUser(userAuth);
    });
    return () => {
      client.resetStore();
      localStorageService.setItem(LocalStorageKey.AUTHENTICATED, "false");
      removeCookie("session_token", {
        path: "/",
        domain: COOKIE_DOMAIN,
      });
      unsub();
    };
  }, []);

  return (
    <UserContext.Provider value={{ user, creator }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
