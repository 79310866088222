import FacebookOnboardingStep from "../../../enums/FacebookOnboardingStep";
import { useEffect } from "react";
import { logView } from "@kalecard/common";

export default function SwitchCreatorAccountCheck({
  setStep,
  setSubstep,
  buttonClassName,
  onError,
}) {
  useEffect(() => {
    logView("facebook_connect", "SwitchCreatorAccountCheck");
  });

  return (
    <>
      <p className="pt-4 text-lg font-bold">
        Oops! Look like you still need to complete a step 🚧
      </p>
      <p className="pt-4">
        Did you <span className="font-bold">switch</span> your account to a
        creator account?
      </p>
      <div className="mt-2 flex items-center space-x-2">
        <button
          onClick={() => {
            setSubstep(FacebookOnboardingStep.LINK_FACEBOOK_PAGE_CHECK);
          }}
          type="button"
          className={buttonClassName}
        >
          Yes
        </button>
        <button
          onClick={() => {
            setStep(FacebookOnboardingStep.SWITCH_CREATOR_ACCOUNT);
            onError();
          }}
          type="button"
          className={buttonClassName}
        >
          No
        </button>
      </div>
    </>
  );
}
