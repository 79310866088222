import { LoginInputs } from "../components/forms/LoginForm";
import { SignUpInputs, VerificationCodeInputs } from "@kalecard/common";
import { auth } from "../config/firebase";
import { localStorageService, LocalStorageKey } from "@kalecard/common";
import { ActionType, ApiType, logAction, logApiError } from "@kalecard/common";
import { GET_CREATOR_SETTINGS } from "../graphql/queries";

export enum SignUpState {
  LOADING = "loading",
  VERIFICATION = "verification",
  SIGNUP = "signup",
  LOGIN = "login",
}

export const onPhoneSubmit = async (
  initialState: SignUpState,
  data: SignUpInputs & LoginInputs,
  setState: (state: SignUpState) => void,
  setFormData: (data: SignUpInputs & LoginInputs) => void,
  setError: (state: string) => void
) => {
  const componentName =
    initialState === SignUpState.SIGNUP ? "SignUpForm" : "LoginForm";
  console.log(data);
  setFormData(data);
  setState(SignUpState.LOADING);

  // Get the referralCode value from local storage
  const referralCode = localStorageService.getItem(
    LocalStorageKey.REFERRAL_CODE
  );
  console.log(referralCode);
  // Whether this new user is coming from a group deal
  const referralGroupDealId = localStorageService.getItem(
    LocalStorageKey.REFERRAL_GROUP_DEAL_ID
  );
  logAction(ActionType.SUBMIT, componentName, {
    phone: data.phoneNumber,
    social_handle: data.socialHandle,
    isTikTokHandle: data.socialMediaPlatform === "tiktok",
    email: data.email,
    referral_code: referralCode,
    referral_group_deal_id: referralGroupDealId,
  });

  const appVerifier = window.recaptchaVerifier;
  auth
    .signInWithPhoneNumber(`+1${data.phoneNumber}`, appVerifier)
    .then((confirmationResult) => {
      console.log("Captcha worked", confirmationResult);
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult;
      setState(SignUpState.VERIFICATION);
      // ...
    })
    .catch((error) => {
      // Error; SMS not sent
      console.error(error);
      if (error.code === "auth/invalid-phone-number") {
        setError("Kale is only available in the US and Canada. Please enter a valid phone number.");
      } else {
        setError(error.message);
      }
      setState(initialState);
    });
};

export const onCodeSubmit = async (
  data: VerificationCodeInputs,
  formData: SignUpInputs & LoginInputs,
  setState: (state: SignUpState) => void,
  setError: (state: string) => void,
  creatorLogin,
  setIsSubmit?: (state: boolean) => void
) => {
  // Get the referralCode value from local storage
  const referralCode = localStorageService.getItem(
    LocalStorageKey.REFERRAL_CODE
  );
  // Whether this new user is coming from a group deal
  const referralGroupDealId = localStorageService.getItem(
    LocalStorageKey.REFERRAL_GROUP_DEAL_ID
  );

  logAction(ActionType.SUBMIT, "VerificationCodeForm", {
    phone: formData.phoneNumber,
    referral_code: referralCode,
    referral_group_deal_id: referralGroupDealId,
  });
  setState(SignUpState.LOADING);
  console.log(data, formData);
  if (window.confirmationResult) {
    window.confirmationResult
      .confirm(data.code)
      .then(async (result: any) => {
        console.log(result);
        // User signed in successfully.
        const user = result.user;
        const tokenId = await user.getIdToken();

        // Pop the referralCode value from local storage
        const referralCode = localStorageService.popItem(
          LocalStorageKey.REFERRAL_CODE
        );

        // Whether this new user is coming from a group deal
        const referralGroupDealId = localStorageService.getItem(
          LocalStorageKey.REFERRAL_GROUP_DEAL_ID
        );
        try {
          const result = await creatorLogin({
            variables: {
              name: formData.name,
              socialHandle: formData.socialHandle,
              referralCode,
              email: formData.email,
              referralGroupDealId,
              isTikTokHandle: formData.socialMediaPlatform === "tiktok",
            },
            context: {
              headers: {
                authorization: tokenId ? `Bearer ${tokenId}` : "",
              },
            },
            refetchQueries: [GET_CREATOR_SETTINGS],
          });
          console.log(result);
          if (setIsSubmit) {
            setIsSubmit(true);
          }
        } catch (err) {
          logApiError(
            ApiType.KALE,
            "creatorLogin",
            "VerificationCodeForm",
            err.message
          );
          console.error(err);
          auth.signOut();
        }
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        console.log(error);
        setError(error.message);
        setState(SignUpState.VERIFICATION);
      });
  }
};
