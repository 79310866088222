import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/remote-config";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD50iC47i0mcGTJG_2WHHJmPRsNoBRjE9o",
  authDomain: "clado-app.firebaseapp.com",
  projectId: "clado-app",
  storageBucket: "clado-app.appspot.com",
  messagingSenderId: "6906346436",
  appId: "1:6906346436:web:60b6d985d08d85b7df4291",
  measurementId: "G-2QJ49QVC9M",
};

firebase.initializeApp(firebaseConfig);

const rc = firebase.remoteConfig();
let rcDefaults = require("./remote_config_defaults.json");
rc.defaultConfig = rcDefaults;

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const analytics = firebase.analytics();
export const remoteConfig = rc;
