import { ApolloProvider } from "@apollo/client";
import "./App.css";
import apolloClient from "./config/apolloClient";
import Application from "./pages/Application";
import UserProvider from "./providers/UserProvider";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import ScrollToTop from "./utils/ScrollToTop";
import FlagsProvider from "./providers/FlagsProvider";

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <UserProvider>
          <FlagsProvider>
            <CookiesProvider>
              <ScrollToTop />
              <Application />
            </CookiesProvider>
          </FlagsProvider>
        </UserProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
