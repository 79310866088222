import { useEffect } from "react";
import FacebookOnboardingStep from "../../../enums/FacebookOnboardingStep";
import InstagramMenuIcon from "../../../images/instagram-menu-icon.jpeg";
import InstagramSettingsIcon from "../../../images/instagram-settings-icon.png";
import { logView } from "@kalecard/common";

export default function SwitchCreatorAccountSteps({
  setStep,
  buttonClassName,
}) {
  useEffect(() => {
    logView("facebook_connect", "SwitchCreatorAccountSteps");
  });

  return (
    <div className="flex flex-col pt-4">
      <p>1. Open the Instagram app.</p>
      <p className="inline-block">
        2. Go to your profile and tap{" "}
        <img
          className="inline-block h-6 w-6"
          alt="Menu"
          src={InstagramMenuIcon}
        />{" "}
        in the upper right corner.
      </p>
      <p className="inline-block">
        3. Tap{" "}
        <img
          className="inline-block h-6 w-6"
          alt="Settings"
          src={InstagramSettingsIcon}
        />{" "}
        <span className="font-bold">Settings</span>.
      </p>
      <p>
        4. Select <span className="font-bold">Account</span>.
      </p>
      <p>
        5. Choose <span className="font-bold">Switch</span> to Professional
        Account and tap Creator.
      </p>
      <div className="flex items-center justify-center pt-8">
        <button
          onClick={() => {
            setStep(FacebookOnboardingStep.LINK_FACEBOOK_PAGE);
          }}
          type="button"
          className={buttonClassName}
        >
          Done
        </button>
      </div>
      <div className="pt-4 text-sm">
        Detailed instructions:{" "}
        <a
          href="https://help.instagram.com/2358103564437429/"
          className="text-blue-500 hover:text-blue-800"
          rel="noopener"
          target="_blank"
        >
          https://help.instagram.com/2358103564437429/
        </a>
      </div>
    </div>
  );
}
