import { useMutation } from "@apollo/client";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  PlaidLinkOnEvent,
  PlaidLinkOnEventMetadata,
  PlaidLinkOptions,
  PlaidLinkStableEvent,
  usePlaidLink,
} from "react-plaid-link";
import {
  PLAID_CREATE_LINK_TOKEN,
  PLAID_EXCHANGE_PUBLIC_TOKEN,
} from "../graphql/mutations";
import { UserContext } from "../providers/UserProvider";
import {
  ActionType,
  ApiType,
  logAction,
  logApiError,
  logApiEvent,
  logApiSuccess,
  logView,
} from "@kalecard/common";
import { Loading } from "@kalecard/common";
import { localStorageService, LocalStorageKey } from "@kalecard/common";

const COMPONENT_NAME = "PlaidLink";

interface LinkProps {
  onSuccess: () => void;
  onClick?: () => void;
  ctaText?: string;
}

export default function PlaidLink(props: LinkProps) {
  const { user } = useContext(UserContext);
  const [linkToken, setLinkToken] = useState<string>(null);
  const [createLinkToken] = useMutation(PLAID_CREATE_LINK_TOKEN);

  useEffect(() => {
    const generateToken = async () => {
      try {
        const result = await createLinkToken();
        localStorageService.setItem(
          LocalStorageKey.PLAID_LINK_TOKEN,
          result.data.plaidCreateLinkToken
        );
        setLinkToken(result.data.plaidCreateLinkToken);
      } catch (err) {
        console.error(err);
      }
    };
    generateToken();
  }, []);

  useEffect(() => {
    if (user) {
      logView(COMPONENT_NAME);
    }
  }, [user]);

  if (!linkToken) {
    return <Loading margin="m-0" color="text-kale-orange-500" />;
  } else {
    return (
      <InternalPlaidLink
        linkToken={linkToken}
        onSuccess={props.onSuccess}
        onClick={props.onClick}
        ctaText={props.ctaText ?? "Link my credit card"}
      />
    );
  }
}

interface InternalLinkProps {
  linkToken: string;
  onSuccess: () => void;
  onClick?: () => void;
  ctaText?: string;
}

function InternalPlaidLink(props: InternalLinkProps) {
  const [plaidExchangePublicToken] = useMutation(PLAID_EXCHANGE_PUBLIC_TOKEN);
  const [loading, setLoading] = useState(false);

  const onEvent = useCallback<PlaidLinkOnEvent>(
    (
      eventName: PlaidLinkStableEvent | string,
      metadata: PlaidLinkOnEventMetadata
    ) => {
      logApiEvent(ApiType.PLAID, eventName, COMPONENT_NAME, metadata);
    },
    []
  );

  const onSuccess = useCallback(async (public_token, metadata) => {
    console.log(public_token, metadata);
    setLoading(true);
    // send public_token to server
    try {
      const result = await plaidExchangePublicToken({
        variables: {
          publicToken: metadata.public_token,
          institutionId: metadata.institution.institution_id,
        },
      });
      console.log(result);
      logApiSuccess(ApiType.PLAID, "plaidExchangePublicToken", COMPONENT_NAME);
      props.onSuccess();
    } catch (err) {
      logApiError(
        ApiType.PLAID,
        "plaidExchangePublicToken",
        COMPONENT_NAME,
        err?.message
      );
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const config: PlaidLinkOptions = {
    token: props.linkToken,
    onSuccess,
    onEvent,
  };

  const { open, ready } = usePlaidLink(config);

  if (loading || !ready) {
    return <Loading margin="m-0" color="text-kale-orange-500" />;
  }

  return (
    <button
      className="inline-flex w-full items-center justify-center rounded-full border border-transparent bg-kale-orange-400 px-4 py-2 text-center text-base text-kale-green-1000 shadow hover:bg-kale-orange-300 disabled:bg-kale-orange-300"
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
        logAction(ActionType.CLICK, COMPONENT_NAME);
        open();
      }}
      disabled={!ready}
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mr-2 -ml-1 h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clipRule="evenodd"
          />
        </svg>
      </span>
      {props?.ctaText}
    </button>
  );
}
