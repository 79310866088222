import { gql } from "@apollo/client";
import {
  BrandLandingPage,
  Challenge,
  Connection,
  CreatorProfileData,
  Deal,
  KaleShop,
} from "@kalecard/common";
export const CREATOR = gql`
  query Creator($userId: ID!) {
    creator(userId: $userId) {
      id
      phoneNumber
      lastDealTimestamp
      name
      email
      referralCode
      creatorSettings {
        isInstagramConnected
        isPlaidConnected
        isPlaidActive
        isTiktokConnected
        experienceLevel
        isBrandCreator
        isBlocked
      }
    }
  }
`;
export const GET_CREATOR_SETTINGS = gql`
  query CreatorSettings {
    creatorSettings {
      isPlaidConnected
      isInstagramConnected
      isGroupBuyingSignup
      referralCode
      isProfileComplete
      isTiktokConnected
      isPlaidActive
      experienceLevel
      isBrandCreator
      isBlocked
    }
  }
`;
export const GET_CREATOR_FACEBOOK_ACCOUNTS = gql`
  query GetCreatorFacebookAccounts {
    getCreatorFacebookAccounts {
      id
      name
      imageUrl
    }
  }
`;
export const FACEBOOK_ACCOUNTS = gql`
  query FacebookAccounts($first: Int, $after: String, $userId: ID) {
    facebookAccounts(first: $first, after: $after, userId: $userId) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          imageUrl
          createdAt
          updatedAt
          followers
          instagramId
          facebookId
        }
      }
    }
  }
`;
export const INSTAGRAM_MEDIA = gql`
  query InstagramMedia(
    $first: Int
    $after: String
    $facebookAccountId: ID!
    $userId: ID
  ) {
    instagramMedia(
      first: $first
      after: $after
      facebookAccountId: $facebookAccountId
      userId: $userId
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          caption
          commentsCount
          likeCount
          mediaType
          permalink
          mediaUrl
          productType
          createdAt
          username
          videoTitle
          insights {
            description
            id
            name
            period
            title
            value
          }
        }
      }
    }
  }
`;
export const COMPLETE_CREATOR_PROFILE_DATA = gql`
  query CreatorProfileData {
    creatorProfileData {
      creator {
        id
        name
        socialHandle
        tiktokSocialHandle
        phoneNumber
        balance
        externalUsers {
          id
          username
          followers
          pictureUrl
          externalPlatform
          status
        }
        creatorSettings {
          experienceLevel
          isInstagramConnected
          isPlaidConnected
          isPlaidActive
          isTiktokConnected
          experienceLevel
          isBrandCreator
          isBlocked
        }
      }
      totalEarnings
      dealsCompleted
      pendingWithdrawalRequests
      pendingWithdrawalBalance
    }
  }
`;
export interface BrandLandingPageDataQuery {
  brandLandingPage: BrandLandingPage;
}
export const BRAND_LANDING_PAGE = gql`
  query BrandLandingPage($referralCode: String!) {
    brandLandingPage(referralCode: $referralCode) {
      brand {
        logoUrl
        name
        referralCode
      }
    }
  }
`;
export const STRIPE_CONNECT_LINK = gql`
  query StripeConnectLink($type: String!) {
    stripeConnectLink(type: $type)
  }
`;
export const FACEBOOK_BUSINESSES = gql`
  query FacebookBusinesses {
    facebookBusinesses {
      id
      name
      link
      profilePictureUri
    }
  }
`;
