import { useEffect, useState } from "react";

export default function StepsComponent({ currentStep, setStep }) {
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps(getSteps(currentStep));
  }, [currentStep]);

  return (
    <nav aria-label="Progress">
      <ol role="list" className="flex md:space-x-8">
        {steps.map((step) => (
          <li key={step.name} className="flex-1 mt-4">
            {step.status === "complete" ? (
              <a
                href={step.href}
                onClick={() => setStep(step.id)}
                className="group pl-4 py-2 flex flex-col border-kale-green-500 hover:border-kale-green-500 md:pl-0 md:pt-4 md:pb-0 border-t-4"
              >
                <span className="text-xs text-kale-green-500 font-semibold tracking-wide uppercase group-hover:text-kale">
                  {step.name}
                </span>
                {/* <span className="text-sm font-medium">{step.name}</span> */}
              </a>
            ) : step.status === "current" ? (
              <a
                href={step.href}
                onClick={() => setStep(step.id)}
                className="pl-4 py-2 flex flex-col border-kale-green-500 md:pl-0 md:pt-4 md:pb-0 border-t-4"
                aria-current="step"
              >
                <span className="text-xs text-kale-green-500 font-semibold tracking-wide uppercase">
                  {step.name}
                </span>
                {/* <span className="text-sm font-medium">{step.name}</span> */}
              </a>
            ) : (
              <a
                href={step.href}
                className="group pl-4 py-2 flex flex-col border-gray-200 md:pl-0 md:pt-4 md:pb-0 border-t-4"
              >
                <span className="text-xs text-gray-500 font-semibold tracking-wide uppercase">
                  {step.name}
                </span>
                {/* <span className="text-sm font-medium">{step.name}</span> */}
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}

function getSteps(currentStep: string) {
  if (
    currentStep === "switch-creator-account" ||
    currentStep === "switch-creator-account-steps"
  ) {
    return [
      {
        name: "Step 1",
        href: "#",
        status: "current",
        id: "switch-creator-account",
      },
      {
        name: "Step 2",
        href: "#",
        status: "upcoming",
        id: "link-facebook-page",
      },
      {
        name: "Step 3",
        href: "#",
        status: "upcoming",
        id: "connect-to-facebook",
      },
    ];
  } else if (
    currentStep === "link-facebook-page" ||
    currentStep === "link-facebook-page-steps"
  ) {
    return [
      {
        name: "Step 1",
        href: "#",
        status: "complete",
        id: "switch-creator-account",
      },
      {
        name: "Step 2",
        href: "#",
        status: "current",
        id: "link-facebook-page",
      },
      {
        name: "Step 3",
        href: "#",
        status: "upcoming",
        id: "connect-to-facebook",
      },
    ];
  } else if (currentStep === "connect-to-facebook") {
    return [
      {
        name: "Step 1",
        href: "#",
        status: "complete",
        id: "switch-creator-account",
      },
      {
        name: "Step 2",
        href: "#",
        status: "complete",
        id: "link-facebook-page",
      },
      {
        name: "Step 3",
        href: "#",
        status: "current",
        id: "connect-to-facebook",
      },
    ];
  } else {
    return [
      {
        name: "Step 1",
        href: "#",
        status: "upcoming",
        id: "switch-creator-account",
      },
      {
        name: "Step 2",
        href: "#",
        status: "upcoming",
        id: "link-facebook-page",
      },
      {
        name: "Step 3",
        href: "#",
        status: "upcoming",
        id: "connect-to-facebook",
      },
    ];
  }
}
