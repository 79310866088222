import { useEffect } from "react";
import { Loading } from "@kalecard/common";
import { auth } from "../config/firebase";

export default function SignoutPage() {
  useEffect(() => {
    auth.signOut();
    return () => {};
  }, []);

  return <Loading />;
}
