import { useQuery } from "@apollo/client";
import { FACEBOOK_BUSINESSES } from "../graphql/queries";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/solid";
import { Loading } from "@kalecard/common";

export default function FacebookPage() {
  const { data, loading } = useQuery(FACEBOOK_BUSINESSES);

  return (
    <div className="px-4">
      <h1 className={"pb-4 text-center text-lg font-bold"}>
        Facebook Business Accounts
      </h1>
      <div className={"flex flex-col space-y-2"}>
        {loading && <Loading />}
        {data?.facebookBusinesses?.map((business) => {
          return (
            <a
              target={"_blank"}
              href={business.link}
              className="flex w-full items-center justify-between rounded-md border border-gray-200 p-2 transition-colors duration-200 ease-in-out hover:bg-gray-100"
              key={business.id}
              rel="noreferrer"
            >
              <div className={"flex space-x-2"}>
                {business.profilePictureUri && (
                  <img
                    className={"h-6 w-6"}
                    src={business.profilePictureUri}
                    alt={"profile picture"}
                  />
                )}
                <h2 className={"font-bold"}>{business.name}</h2>
              </div>
              <ChevronDoubleRightIcon className={"h-4 w-4"} />
            </a>
          );
        })}
      </div>
    </div>
  );
}
