import { Redirect, Route } from "react-router-dom";
import SkeletonPage from "./SkeletonPage";

export default function AuthRoute({
  component: Component,
  authenticated,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        var fromLocation = props.location;
        const toPathname = getToPathname(props.location.pathname);

        // Sign out is a special case
        if (props.location.pathname === "/signout") {
          fromLocation = null;
        }

        return !authenticated ? (
          <Redirect
            to={{ pathname: toPathname, state: { from: fromLocation } }}
          />
        ) : (
          <SkeletonPage>
            <Component {...rest.props} />
          </SkeletonPage>
        );
      }}
    />
  );
}

function getToPathname(pathname: string) {
  if (pathname.startsWith("/g/")) {
    const groupDealPathname = pathname.split("/");
    if (groupDealPathname[groupDealPathname.length - 1] === "activate") {
      groupDealPathname.pop();
      return groupDealPathname.join("/");
    }
  }
  return "/login";
}
