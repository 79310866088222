import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import {
  NavLink,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";
import InstagramTabsHeader from "../components/InstagramTabsHeader";
import InstagramInsights from "../components/InstagramInsights";
import { Loading } from "@kalecard/common";
import SelectionComponent, {
  SelectionComponentItem,
} from "../components/SelectionComponent";
import { auth } from "../config/firebase";
import { FACEBOOK_ACCOUNTS } from "../graphql/queries";
import { UserContext } from "../providers/UserProvider";

export default function InstagramPage() {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [accounts, setAccounts] = useState(null);
  const params = useParams();

  const loadData = (data) => {
    const accounts = data.facebookAccounts.edges.map((account) => {
      return account.node;
    });
    if (accounts.length > 0) {
      setAccounts(accounts);
    } else {
      history.push("/connect/facebook");
    }
  };

  const [getAccounts] = useLazyQuery(FACEBOOK_ACCOUNTS, {
    fetchPolicy: "network-only",
    onCompleted: loadData,
  });

  const getFacebookAccounts = async () => {
    if (user) {
      const tokenId = await auth.currentUser.getIdToken();
      getAccounts({
        variables: {
          first: 50,
        },
        context: {
          headers: {
            authorization: tokenId ? `Bearer ${tokenId}` : "",
          },
        },
      });
    }
  };

  const handleAccountSelected = (item: SelectionComponentItem) => {
    if (item.id !== "-1") {
      history.push(`/instagram/${item.id}/insights`);
    }
  };

  useEffect(() => {
    getFacebookAccounts();
  }, [user]);

  return (
    <>
      <main>
        <div className="px-4">
          {user && user.uid === "KSHBuaYdqLa0wIOVZ05fhly5PTh1" && (
            <NavLink
              className="text-sm underline hover:text-blue-500"
              to="/facebook"
            >
              View Facebook Business Accounts
            </NavLink>
          )}
          {accounts && accounts.length > 0 ? (
            <>
              <SelectionComponent
                list={accounts}
                selectedId={params["id"]}
                onSelect={handleAccountSelected}
                defaultTitle="Choose an account"
              />
              {params["id"] && <InstagramTabsHeader id={params["id"]} />}
            </>
          ) : (
            <>
              <Loading />
            </>
          )}
          <div className="mt-4">
            {accounts &&
              accounts.map((account) => {
                if (account.id === params["id"]) {
                  return (
                    <Switch key={account.id}>
                      <Route path="/instagram/:id/insights">
                        <InstagramInsights facebookAccountId={account.id} />
                      </Route>
                    </Switch>
                  );
                }
              })}
          </div>
        </div>
      </main>
    </>
  );
}
