import { Media, numberWithCommas, timeDifferenceForDate } from "@kalecard/common";

interface InstagramMediaComponentInerface {
  media: Media;
}

export default function InstagramMediaComponent(
  props: InstagramMediaComponentInerface
) {
  const captionLink = (
    <a
      className="whitespace-pre-line text-sm font-semibold text-indigo-600 line-clamp-6 hover:text-indigo-900"
      href={props.media.permalink}
      target="_blank"
      rel="noreferrer"
    >
      {props.media.caption ? props.media.caption : "(empty caption)"}
    </a>
  );

  var engagement = 0;
  var reach = 0;
  props.media.insights.map((insight) => {
    if (insight.title === "Engagement") {
      engagement = Number(insight.value);
    }
    if (insight.title === "Reach") {
      reach = Number(insight.value);
    }
  });

  return (
    <tr>
      <td className="max-w-xs truncate px-2 py-2">
        {captionLink}
        <div className="text-sm text-gray-500">
          {timeDifferenceForDate(Number(props.media.createdAt))} -{" "}
          <a
            className="text-sm font-semibold text-indigo-600 hover:text-indigo-900"
            href={props.media.mediaUrl}
            target="_blank"
            rel="noreferrer"
          >
            View media
          </a>
        </div>
      </td>
      <td className="whitespace-nowrap px-2 py-2 text-sm">
        <div className="flex flex-col">
          {props.media.insights.map((insight) => {
            return (
              <span key={insight.id}>
                {`${insight.title}: ${numberWithCommas(insight.value)} `}
              </span>
            );
          })}
          <span>Likes: {numberWithCommas(props.media.likeCount)}</span>
          <span>Comments: {numberWithCommas(props.media.commentsCount)}</span>
        </div>
      </td>
    </tr>
  );
}
