import { useEffect } from "react";
import { Loading } from "@kalecard/common";

export default function PlaidAppConnection() {
  useEffect(() => {
    window.location.href = `kale://plaid`;
  }, []);

  return (
    <div className="flex w-full flex-col px-4">
      <h2 className="text-center text-xl">
        Just a few more seconds while we finish the connection with your bank
        provider.
      </h2>
      <Loading />
    </div>
  );
}
