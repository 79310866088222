import { useEffect } from "react";
import { logView } from "@kalecard/common";

export default function ConnectFacebook({ facebookLoginButton }) {
  useEffect(() => {
    logView("facebook_connect", "ConnectFacebook");
  });

  return (
    <div className="flex flex-col justify-center">
      <div className="flex justify-center pt-4">
        Hooray you're almost done! 🎉
      </div>
      <div className="flex justify-center pt-4">{facebookLoginButton}</div>
    </div>
  );
}
