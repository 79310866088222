import { Loading, logError, logView } from "@kalecard/common";
import { useContext, useEffect, useState } from "react";
import useLocationQuery from "../hooks/useLocationQuery";
import { UserContext } from "../providers/UserProvider";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { OAUTH_TIKTOK } from "../graphql/mutations";
import { useCookies } from "react-cookie";

const TIKTOK_BUSINESS_APP_ID = "7269572337366728706";

export default function TiktokOAuthPage() {
  const query = useLocationQuery();
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [oauthTiktok] = useMutation(OAUTH_TIKTOK);
  const [cookies] = useCookies(["tiktok_state"]);
  const [errorMessage, setErrorMessage] = useState<string>(null);

  const code = query.get("code");
  const scopes = query.get("scopes");
  const error = query.get("error");
  const state = query.get("state");

  const exchangeToken = async () => {
    try {
      const result = await oauthTiktok({
        variables: {
          code,
          scopes,
          appId: TIKTOK_BUSINESS_APP_ID,
        },
      });
      console.log(result);
      if (result.data.oauthTiktok) {
        history.push("/");
      } else {
        setErrorMessage("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    logView("TiktokOAuthPage");

    if (error) {
      logError(error);
      setErrorMessage(
        "You are not eligible for using Tiktok's third-party login/authorization. Please change your Tiktok configurations and try again."
      );
    }

    if (user) {
      if (cookies.tiktok_state === state) {
        exchangeToken();
      } else {
        logError("error: state mismatch");
        setErrorMessage("Something went wrong. Please try again.");
      }
    }

    if (state === "new") {
      window.location.href = `kale://connect/tiktok/oauth/?code=${code}&state=${state}&scopes=${scopes}&error=${error}`;
    }

    // @ts-ignore
    window &&
      // @ts-ignore
      window.ReactNativeWebView &&
      // @ts-ignore
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ code, scopes, errorMessage })
      );
  }, [user]);

  var content = null;
  const connectTiktokButton = (
    <a
      href="https://api.kalecard.com/oauth/tiktok?platform=mobile"
      className="inline-flex w-full items-center justify-center rounded-full border border-transparent bg-kale-orange-400 px-4 py-2 text-center text-base text-kale-green-1000 shadow hover:bg-kale-orange-300 disabled:bg-kale-orange-300"
    >
      <span className="mr-1 h-6 w-6">
        <svg fill="currentColor" viewBox="0 0 30 30" width="24px" height="24px">
          <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.104,4,24,4z M22.689,13.474 c-0.13,0.012-0.261,0.02-0.393,0.02c-1.495,0-2.809-0.768-3.574-1.931c0,3.049,0,6.519,0,6.577c0,2.685-2.177,4.861-4.861,4.861 C11.177,23,9,20.823,9,18.139c0-2.685,2.177-4.861,4.861-4.861c0.102,0,0.201,0.009,0.3,0.015v2.396c-0.1-0.012-0.197-0.03-0.3-0.03 c-1.37,0-2.481,1.111-2.481,2.481s1.11,2.481,2.481,2.481c1.371,0,2.581-1.08,2.581-2.45c0-0.055,0.024-11.17,0.024-11.17h2.289 c0.215,2.047,1.868,3.663,3.934,3.811V13.474z" />
        </svg>
      </span>
      Connect Tiktok
    </a>
  );

  if (errorMessage) {
    content = (
      <div className="flex flex-col space-y-2">
        <h2 className="text-center text-xl">{errorMessage}</h2>
        {connectTiktokButton}
      </div>
    );
  } else if (code) {
    content = (
      <>
        <h2 className="text-center text-xl">
          Just a few more seconds while we finish the connection with Tiktok. 🤖
        </h2>
        <Loading />
      </>
    );
  } else {
    content = <>{connectTiktokButton}</>;
  }

  return <div className="flex w-full flex-col px-4">{content}</div>;
}
