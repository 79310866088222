import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { API_URL } from "@kalecard/common";
import { ApiType, logApiError } from "@kalecard/common";
import { auth } from "./firebase";

const authLink = setContext((_, { headers }) => {
  // If there is already an authorization header, do nothing
  if (headers && headers.authorization) {
    return {
      headers: {
        ...headers,
      },
    };
  }

  //it will always get unexpired version of the token
  return auth && auth.currentUser
    ? auth.currentUser.getIdToken().then((token) => {
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
          },
        };
      })
    : {
        headers: {
          ...headers,
        },
      };
});

const httpLink = createHttpLink({
  uri: `${API_URL}/graphql/`,
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    if (graphQLErrors)
      graphQLErrors.forEach((err) => {
        console.error(
          `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
        );
        logApiError(ApiType.KALE, operation.operationName, "", err.message, {
          type: "graphql",
          code: err.extensions.code,
        });
      });

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
      try {
        const {
          response: { headers },
        } = operation.getContext();
        logApiError(
          ApiType.KALE,
          operation.operationName,
          "",
          networkError.message,
          {
            type: "network",
            ...networkError,
            ...operation.extensions,
            ...operation.getContext(),
            headers: { ...headers },
            cause: networkError.cause,
            name: networkError.name,
            stack: networkError.stack,
            response,
          }
        );
      } catch (e) {
        logApiError(
          ApiType.KALE,
          operation.operationName,
          "catch-all-network-error",
          networkError.message,
          {
            type: "network",
            ...networkError,
          }
        );
      }
    }
  }
);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, authLink, httpLink]),
});

export default client;
