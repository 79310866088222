import { useEffect } from "react";
import FacebookOnboardingStep from "../../../enums/FacebookOnboardingStep";
import { logView } from "@kalecard/common";

export default function LinkFacebookPageCheck({
  setStep,
  setSubstep,
  buttonClassName,
  onError,
}) {
  useEffect(() => {
    logView("facebook_connect", "LinkFacebookPageCheck");
  });

  return (
    <>
      <p className="pt-4 text-lg font-bold">
        Oops! Look like you still need to complete a step 🚧
      </p>
      <p className="pt-4">
        Did you link your account to a{" "}
        <span className="font-bold">Facebook Page</span>?
      </p>
      <div className="mt-2 flex items-center space-x-2">
        <button
          onClick={() => {
            setSubstep(FacebookOnboardingStep.FACEBOOK_SCREENSHOTS);
          }}
          type="button"
          className={buttonClassName}
        >
          Yes
        </button>
        <button
          onClick={() => {
            setStep(FacebookOnboardingStep.LINK_FACEBOOK_PAGE);
            onError();
          }}
          type="button"
          className={buttonClassName}
        >
          No
        </button>
      </div>
    </>
  );
}
