import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { auth } from "../../../config/firebase";
import {
  COMPLETE_CREATOR_PROFILE_DATA,
  GET_CREATOR_FACEBOOK_ACCOUNTS,
} from "../../../graphql/queries";
import { UserContext } from "../../../providers/UserProvider";
import FacebookAccount, { FacebookAccountProps } from "../../FacebookAccount";
import { Loading } from "@kalecard/common";
import ApiError from "../../ApiError";
import { CHOOSE_CREATOR_FACEBOOK_ACCOUNTS } from "../../../graphql/mutations";
import FacebookOnboardingStep from "../../../enums/FacebookOnboardingStep";
import SwitchCreatorAccountCheck from "./SwitchCreatorAccountCheck";
import LinkFacebookPageCheck from "./LinkFacebookPageCheck";
import FacebookScreenshots from "./FacebookScreenshots";
import { ApiType, logApiError, logApiSuccess } from "@kalecard/common";

interface FacebookAccountSelectionComponentProps {
  onSuccess: () => void;
  setStep: (step: FacebookOnboardingStep) => void;
  onError: () => void;
  facebookLoginButton: JSX.Element;
}

export default function FacebookAccountSelectionComponent({
  onSuccess,
  setStep,
  onError,
  facebookLoginButton,
}: FacebookAccountSelectionComponentProps) {
  const { user, creator } = useContext(UserContext);
  const { data: creatorData } = useQuery(COMPLETE_CREATOR_PROFILE_DATA);
  const [isEmptyAccounts, setIsEmptyAccounts] = useState<boolean>(false);
  const [accounts, setAccounts] = useState([]);
  const [substep, setSubstep] = useState(
    FacebookOnboardingStep.SWITCH_CREATOR_ACCOUNT_CHECK
  );

  const automaticallyChooseAccount = async (accountIds) => {
    try {
      const tokenId = await auth.currentUser.getIdToken();
      const result = await chooseFacebookAccounts({
        variables: {
          facebookIds: accountIds,
        },
        context: {
          headers: {
            authorization: tokenId ? `Bearer ${tokenId}` : "",
          },
        },
      });
      logApiSuccess(
        ApiType.KALE,
        "chooseFacebookAccounts",
        "FacebookAccountSelectionComponent"
      );
      console.log(result);
      onSuccess();
    } catch (err) {
      logApiError(
        ApiType.KALE,
        "chooseFacebookAccounts",
        "FacebookAccountSelectionComponent",
        err.message
      );
      console.error(err);
    }
  };

  const loadData = async (data) => {
    console.log(data);
    if (data.getCreatorFacebookAccounts.length === 0) {
      setIsEmptyAccounts(true);
      logApiError(
        ApiType.KALE,
        "chooseFacebookAccounts",
        "FacebookAccountSelectionComponent",
        "no_facebook_accounts"
      );
    } else {
      const accountIds = data.getCreatorFacebookAccounts.map(
        (account: FacebookAccountProps) => {
          return account.id;
        }
      );
      if (creator.creatorSettings?.isBlocked) {
        setAccounts(data.getCreatorFacebookAccounts);
      } else {
        automaticallyChooseAccount(accountIds);
      }
    }
  };

  const [getFacebookAccounts, { error }] = useLazyQuery(
    GET_CREATOR_FACEBOOK_ACCOUNTS,
    {
      fetchPolicy: "network-only",
      onCompleted: loadData,
    }
  );
  const [chooseFacebookAccounts] = useMutation(
    CHOOSE_CREATOR_FACEBOOK_ACCOUNTS
  );

  useEffect(() => {
    if (user && creatorData?.creatorProfileData) {
      getFacebookAccounts();
    }
  }, [user, creatorData]);

  const buttonClassName =
    "w-1/3 inline-flex items-center justify-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";

  let content = null;
  switch (substep) {
    case FacebookOnboardingStep.SWITCH_CREATOR_ACCOUNT_CHECK:
      content = (
        <SwitchCreatorAccountCheck
          setStep={setStep}
          setSubstep={setSubstep}
          buttonClassName={buttonClassName}
          onError={onError}
        />
      );
      break;
    case FacebookOnboardingStep.LINK_FACEBOOK_PAGE_CHECK:
      content = (
        <LinkFacebookPageCheck
          setStep={setStep}
          setSubstep={setSubstep}
          buttonClassName={buttonClassName}
          onError={onError}
        />
      );
      break;
    case FacebookOnboardingStep.FACEBOOK_SCREENSHOTS:
      content = (
        <FacebookScreenshots facebookLoginButton={facebookLoginButton} />
      );
      break;
    default:
      content = (
        <div className="flex flex-col">
          <p>
            Text <span className="font-bold">HELP</span> to customer service to
            finish setting up your account. We're on standby to help you.
          </p>
          <a
            className="text-blue-500 hover:text-blue-800"
            href="tel:+15154171709"
          >
            (515) 417-1709
          </a>
        </div>
      );
      break;
  }

  if (!creator) {
    return <Loading />;
  }

  if (creator?.creatorSettings?.isBlocked) {
    return (
      <>
        {accounts.length > 0 ? (
          <div>
            <p>Choose one account:</p>
            <div className="flex flex-col space-y-2">
              {accounts.map((account) => {
                return (
                  <FacebookAccount
                    id={account.id}
                    name={account.name}
                    imageUrl={account.imageUrl}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </>
    );
  }

  if (error) return <ApiError error={error} />;
  if (isEmptyAccounts) return <div className="flex flex-col">{content}</div>;
  return <Loading />;
}
