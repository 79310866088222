import { Switch, Route } from "react-router-dom";
import SignUp from "./SignUp";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../providers/UserProvider";
import LegalPage from "./LegalPage";
import FacebookConnectPage from "./FacebookConnectPage";
import PlaidConnectPage from "./PlaidConnectPage";
import TermsPage from "./TermsPage";
import InstagramPage from "./InstagramPage";
import AuthRoute from "./AuthRoute";
import SignoutPage from "./SignoutPage";
import OnboardingSkeleton from "./OnboardingSkeleton";
import { localStorageService, LocalStorageKey } from "@kalecard/common";
import HomePage from "./HomePage";
import TiktokConnectPage from "./TiktokConnectPage";
import TiktokOAuthPage from "./TiktokOAuthPage";
import StripeConnectLinkPage from "./StripeConnectLinkPage";
import LandingPage from "./LandingPage";
import StripeConnectMobilePage from "./StripeConnectMobilePage";
import FacebookPage from "./FacebookPage";
import FacebookOAuthPage from "./FacebookOAuthPage";
import PlaidAppConnection from "./PlaidAppConnection";

function Application() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorageService.getBoolean(LocalStorageKey.AUTHENTICATED)
  );
  const { user } = useContext(UserContext);

  useEffect(() => {
    setIsAuthenticated(
      localStorageService.getBoolean(LocalStorageKey.AUTHENTICATED)
    );
    return () => {};
  }, [user]);

  return (
    <div className="font-kale-body">
      <Switch>
        <AuthRoute
          path="/instagram/:id"
          component={InstagramPage}
          props={{ isAuthenticated: isAuthenticated }}
          authenticated={isAuthenticated}
        />
        <AuthRoute
          path="/instagram"
          component={InstagramPage}
          authenticated={isAuthenticated}
        />
        <AuthRoute
          path="/facebook"
          component={FacebookPage}
          authenticated={isAuthenticated}
        />
        <AuthRoute
          path="/payouts"
          authenticated={isAuthenticated}
          component={StripeConnectLinkPage}
          props={{ linkType: "DASHBOARD" }}
        />
        <AuthRoute
          path="/stripe/onboarding"
          authenticated={isAuthenticated}
          component={StripeConnectLinkPage}
          props={{ linkType: "ON_BOARDING" }}
        />
        <AuthRoute
          path="/stripe/dashboard"
          authenticated={isAuthenticated}
          component={StripeConnectLinkPage}
          props={{ linkType: "DASHBOARD" }}
        />
        <Route path="/connect/facebook/oauth">
          <FacebookOAuthPage />
        </Route>
        <AuthRoute
          path="/connect/facebook"
          component={FacebookConnectPage}
          authenticated={isAuthenticated}
        />
        <Route path="/connect/tiktok/oauth">
          <TiktokOAuthPage />
        </Route>
        <Route path="/connect/stripe/mobile/:type">
          <StripeConnectMobilePage />
        </Route>
        <AuthRoute
          path="/connect/tiktok"
          component={TiktokConnectPage}
          authenticated={isAuthenticated}
        />
        <AuthRoute
          path="/connect/plaid/oauth"
          component={PlaidConnectPage}
          props={{ isOAuth: true }}
          authenticated={isAuthenticated}
        />
        <AuthRoute
          path="/connect/plaid"
          component={PlaidConnectPage}
          props={{ isOAuth: false }}
          authenticated={isAuthenticated}
        />
        <Route path="/plaid">
          <PlaidAppConnection />
        </Route>
        <Route path="/signup/:referralCode?">
          <OnboardingSkeleton>
            <SignUp isSignIn={false} authenticated={isAuthenticated} />
          </OnboardingSkeleton>
        </Route>
        <Route path="/login">
          <OnboardingSkeleton>
            <SignUp isSignIn={true} authenticated={isAuthenticated} />
          </OnboardingSkeleton>
        </Route>
        <Route path="/legal">
          <LegalPage />
        </Route>
        <Route path="/terms">
          <TermsPage />
        </Route>
        <AuthRoute
          path="/signout"
          authenticated={isAuthenticated}
          component={SignoutPage}
        />
        <Route path="/">
          {isAuthenticated ? <LandingPage /> : <HomePage />}
        </Route>
      </Switch>
    </div>
  );
}
export default Application;
