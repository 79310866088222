import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { auth } from "../../config/firebase";
import { INSTAGRAM_MEDIA } from "../../graphql/queries";
import { UserContext } from "../../providers/UserProvider";
import InstagramMediaComponent from "../InstagramMediaComponent";
import { Spinner } from "@kalecard/common";

interface InstagramMediaListInterface {
  facebookAccountId: string;
}

export default function InstagramMediaList(props: InstagramMediaListInterface) {
  const { user } = useContext(UserContext);
  const [edges, setEdges] = useState([]);
  const [after, setAfter] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);

  const setMedia = (data: any) => {
    console.log(data);
    setHasNextPage(data.instagramMedia.pageInfo.hasNextPage);
    setAfter(
      data.instagramMedia.edges[data.instagramMedia.edges.length - 1]?.cursor
    );
    setEdges([...edges, ...data.instagramMedia.edges]);
  };

  const [getMedia, { loading }] = useLazyQuery(INSTAGRAM_MEDIA, {
    fetchPolicy: "cache-and-network",
    onCompleted: setMedia,
  });

  const loadData = async () => {
    if (user) {
      const tokenId = await auth.currentUser.getIdToken();
      loadMore(tokenId);
    }
  };

  useEffect(() => {
    loadData();
    return () => {};
  }, [user]);

  const loadMore = async (tokenId: string) => {
    if (!tokenId) {
      tokenId = await auth.currentUser.getIdToken();
    }

    getMedia({
      variables: {
        first: 20,
        after,
        facebookAccountId: Number(props.facebookAccountId),
      },
      context: {
        headers: {
          authorization: tokenId ? `Bearer ${tokenId}` : "",
        },
      },
    });
  };

  var listFooter = null;
  if (hasNextPage) {
    listFooter = (
      <button
        className="rounded border border-emerald-500 bg-transparent py-2 px-6 text-center font-semibold text-emerald-700 hover:border-transparent hover:bg-emerald-500 hover:text-white"
        onClick={() => loadMore(null)}
      >
        Load more
      </button>
    );
  }

  if (loading) {
    listFooter = (
      <div className="flex flex-wrap content-center">
        <Spinner size="h-6 w-6" />
      </div>
    );
  }

  return (
    <div className="mt-8 flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Caption
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Insights
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {edges.map((edge) => (
                  <InstagramMediaComponent
                    key={edge.node.id}
                    media={edge.node}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {listFooter}
    </div>
  );
}
