import { useQuery } from "@apollo/client";
import { Loading } from "@kalecard/common";
import { STRIPE_CONNECT_LINK } from "../graphql/queries";

export default function StripeConnectLinkPage({
  linkType,
}: {
  linkType: string;
}) {
  const completed = (data) => {
    window.location.href = data.stripeConnectLink;
  };

  useQuery(STRIPE_CONNECT_LINK, {
    variables: {
      type: linkType,
    },
    onCompleted: completed,
  });

  return (
    <>
      <Loading />
    </>
  );
}
