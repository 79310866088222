import { createContext, useContext, useEffect, useState } from "react";
import { remoteConfig } from "../config/firebase";
import { UserContext } from "./UserProvider";

interface FeaturesInterface {
  isPayoutsEnabled: boolean;
}

const FlagsContext = createContext<{
  remoteConfig: firebase.default.remoteConfig.RemoteConfig;
  features: FeaturesInterface;
}>(null);

// For development only
remoteConfig.settings = {
  fetchTimeoutMillis: 30000, // 30 seconds
  minimumFetchIntervalMillis: 900000, // 15 mins
};

const defaultFeatures = {
  isChallengesEnabled: true,
  isPayoutsEnabled: true,
};

const FlagsProvider = ({ children }) => {
  const [flags, setFlags] = useState(remoteConfig);
  const [features, setFeatures] = useState<FeaturesInterface>(defaultFeatures);
  const { user } = useContext(UserContext);

  useEffect(() => {
    remoteConfig
      .fetchAndActivate()
      .then((activated) => {
        if (!activated) console.log("not activated");
        setFlags(remoteConfig);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (user) {
      setFeatures({
        isPayoutsEnabled: true,
      });
    }
  }, [user]);

  return (
    <FlagsContext.Provider value={{ remoteConfig: flags, features: features }}>
      {children}
    </FlagsContext.Provider>
  );
};

export default FlagsProvider;

export const useFlags = () => {
  const context = useContext(FlagsContext);
  return context;
};
