import { useEffect } from "react";
import FacebookOnboardingStep from "../../../enums/FacebookOnboardingStep";
import { logView } from "@kalecard/common";

export default function LinkFacebookPage({ setStep, buttonClassName }) {
  useEffect(() => {
    logView("facebook_connect", "LinkFacebookPage");
  });

  return (
    <>
      <p className="pt-4 font-bold">
        Is your Instagram account connected to a Facebook page?
      </p>
      <p className="text-sm text-gray-600">
        Since Facebook owns Instagram, your Instagram account must be linked to
        a Facebook Page so that Kale can verify when you mention a brand.
      </p>
      <div className="mt-2 flex flex-col items-center space-y-2">
        <button
          onClick={() => {
            setStep(FacebookOnboardingStep.CONNECT_TO_FACEBOOK);
          }}
          type="button"
          className={buttonClassName}
        >
          Yes
        </button>
        <button
          onClick={() => {
            setStep(FacebookOnboardingStep.LINK_FACEBOOK_PAGE_STEPS);
          }}
          type="button"
          className={buttonClassName}
        >
          No
        </button>
        <button
          onClick={() => {
            setStep(FacebookOnboardingStep.LINK_FACEBOOK_PAGE_STEPS);
          }}
          type="button"
          className={buttonClassName}
        >
          I don't know
        </button>
      </div>
    </>
  );
}
