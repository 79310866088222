/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import {
  ArrowsUpDownIcon,
  CheckIcon,
  UserIcon,
} from "@heroicons/react/24/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export interface SelectionComponentItem {
  id: string;
  imageUrl?: string;
  name: string;
  path?: string;
}

interface SelectionComponentProps {
  list: SelectionComponentItem[];
  selectedId: string;
  onSelect: (item: SelectionComponentItem) => void;
  defaultTitle: string;
  showImage?: boolean | undefined;
}

export default function SelectionComponent({
  list,
  selectedId,
  onSelect,
  defaultTitle,
  showImage,
}: SelectionComponentProps) {
  function chooseDefault(
    list: SelectionComponentItem[],
    selectedId: string
  ): SelectionComponentItem {
    const defaultItem: SelectionComponentItem = {
      name: defaultTitle,
      id: "-1",
      imageUrl: null,
    };

    if (selectedId) {
      const selectedItem = list.find(
        (item: SelectionComponentItem) => item.id === selectedId
      );
      if (selectedItem) {
        return selectedItem;
      } else {
        return defaultItem;
      }
    } else {
      return defaultItem;
    }
  }

  const [selected, setSelected] = useState<SelectionComponentItem>(
    chooseDefault(list, selectedId)
  );

  const handleSelectedItem = (item: SelectionComponentItem) => {
    setSelected(item);
    onSelect(item);
  };

  useEffect(() => {
    setSelected(chooseDefault(list, selectedId));
  }, [selectedId]);

  return (
    <Listbox value={selected} onChange={handleSelectedItem}>
      {({ open }) => (
        <>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-kale-mint-500 focus:outline-none focus:ring-1 focus:ring-kale-mint-500 sm:text-sm">
              <span className="text-kale flex items-center">
                {showImage !== false &&
                  (selected.imageUrl ? (
                    <img
                      src={selected.imageUrl}
                      alt=""
                      className="mr-3 h-6 w-6 shrink-0 rounded-full"
                    />
                  ) : (
                    <UserIcon className="mr-3 h-6 w-6 shrink-0 rounded-full" />
                  ))}
                <span className="block truncate">{selected.name}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ArrowsUpDownIcon
                  className="text-kale h-5 w-5"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {list.map((item) => (
                  <Listbox.Option
                    key={item.id}
                    className={({ active }) =>
                      classNames(
                        active
                          ? "bg-kale-mint-500 text-kale-green-500"
                          : "text-kale",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          {showImage !== false &&
                            (item.imageUrl ? (
                              <img
                                src={item.imageUrl}
                                alt=""
                                className="mr-3 h-6 w-6 shrink-0 rounded-full"
                              />
                            ) : (
                              <UserIcon className="mr-3 h-6 w-6 shrink-0 rounded-full" />
                            ))}
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {item.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-kale" : "text-kale-mint-500",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
