import { useState, useContext, useEffect } from "react";
import { auth } from "../config/firebase";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { useMutation } from "@apollo/client";
import { UPDATE_CREATOR_FACEBOOK_ACCESS_TOKEN } from "../graphql/mutations";
import { UserContext } from "../providers/UserProvider";
import { Loading } from "@kalecard/common";
import FacebookAccountSelectionComponent from "../components/onboarding/facebook/FacebookAccountSelectionComponent";
import { useHistory } from "react-router";
import StepsComponent from "../components/StepsComponent";
import ConnectFacebook from "../components/onboarding/facebook/ConnectFacebook";
import SwitchCreatorAccount from "../components/onboarding/facebook/SwitchCreatorAccount";
import SwitchCreatorAccountSteps from "../components/onboarding/facebook/SwitchCreatorAccountSteps";
import LinkFacebookPage from "../components/onboarding/facebook/LinkFacebookPage";
import LinkFacebookPageSteps from "../components/onboarding/facebook/LinkFacebookPageSteps";
import FacebookOnboardingStep from "../enums/FacebookOnboardingStep";
import {
  ActionType,
  ApiType,
  logAction,
  logApiError,
  logApiSuccess,
} from "@kalecard/common";
import {
  COMPLETE_CREATOR_PROFILE_DATA,
  GET_CREATOR_SETTINGS,
} from "../graphql/queries";

export const facebookScopes = new Set<string>();
// Setting up access to instagram
facebookScopes.add("instagram_basic");
facebookScopes.add("pages_show_list");
facebookScopes.add("business_management");

// Setting up webhooks
// https://developers.facebook.com/docs/instagram-api/guides/webhooks
facebookScopes.add("instagram_manage_insights");
// facebookScopes.add("instagram_manage_comments");
facebookScopes.add("pages_manage_metadata");

const FacebookConnectPage = () => {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [updateFacebookAccessToken] = useMutation(
    UPDATE_CREATOR_FACEBOOK_ACCESS_TOKEN
  );
  const [errorMessage, setErrorMessage] = useState(null);

  const [isFacebookConnected, setIsFacebookConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(
    FacebookOnboardingStep.SWITCH_CREATOR_ACCOUNT
  );

  const onFacebookConnected = async () => {
    history.push("/");
  };

  useEffect(() => {
    // For Facebook App Reviewer only
    // if (user && user.uid === "KSHBuaYdqLa0wIOVZ05fhly5PTh1") {
    //   // Manage businesses
    //   facebookScopes.add("business_management");
    // }
    return () => {};
  }, [user]);

  // This connects to the Facebook account only returning the access token
  const responseFacebook = async (userInfo) => {
    setIsLoading(true);
    if (userInfo.error) {
      // setErrorMessage("Failed to authenticate with Facebook");
      console.error(userInfo.error);
      logApiError(
        ApiType.FACEBOOK,
        "facebook_login",
        "facebookLoginButton",
        userInfo.status
      );
    } else {
      console.log(userInfo);
      logApiSuccess(ApiType.FACEBOOK, "facebook_login", "facebookLoginButton");
      try {
        const tokenId = await auth.currentUser.getIdToken();
        const result = await updateFacebookAccessToken({
          variables: {
            accessToken: userInfo.accessToken,
            scopes: Array.from(facebookScopes).join(","),
            domain: "app.kalecard.com",
          },
          context: {
            headers: {
              authorization: tokenId ? `Bearer ${tokenId}` : "",
            },
          },
          refetchQueries: [COMPLETE_CREATOR_PROFILE_DATA, GET_CREATOR_SETTINGS],
        });
        console.log(result);
        setIsFacebookConnected(true);
        logApiSuccess(
          ApiType.KALE,
          "updateFacebookAccessToken",
          "facebookLoginButton"
        );
      } catch (err) {
        console.error(err);
        logApiError(
          ApiType.KALE,
          "updateFacebookAccessToken",
          "facebookLoginButton",
          err.message
        );
      }
    }
    setIsLoading(false);
  };

  const onError = () => {
    setIsFacebookConnected(false);
  };

  const facebookLoginButton = (
    <FacebookLogin
      appId="703662107281306"
      autoLoad={false}
      fields="name,email,picture"
      scope={Array.from(facebookScopes).join(",")}
      onSuccess={responseFacebook}
      style={{ display: "flex", justifyContent: "start" }}
      render={({ onClick }) => (
        <button
          className="rounded bg-fb-blue py-2 px-4 uppercase text-white hover:bg-blue-600"
          onClick={() => {
            setIsLoading(true);
            setIsFacebookConnected(false);
            logAction(ActionType.CLICK, "facebookLoginButton");
            onClick();
          }}
        >
          CONNECT WITH FACEBOOK
        </button>
      )}
    />
  );

  const buttonClassName =
    "w-1/3 inline-flex items-center justify-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";

  var content = null;
  switch (step) {
    case FacebookOnboardingStep.SWITCH_CREATOR_ACCOUNT:
      content = (
        <SwitchCreatorAccount
          buttonClassName={buttonClassName}
          setStep={setStep}
        />
      );
      break;
    case FacebookOnboardingStep.SWITCH_CREATOR_ACCOUNT_STEPS:
      content = (
        <SwitchCreatorAccountSteps
          buttonClassName={buttonClassName}
          setStep={setStep}
        />
      );
      break;
    case FacebookOnboardingStep.LINK_FACEBOOK_PAGE:
      content = (
        <LinkFacebookPage buttonClassName={buttonClassName} setStep={setStep} />
      );
      break;
    case FacebookOnboardingStep.LINK_FACEBOOK_PAGE_STEPS:
      content = (
        <LinkFacebookPageSteps
          buttonClassName={buttonClassName}
          setStep={setStep}
        />
      );
      break;
    case FacebookOnboardingStep.CONNECT_TO_FACEBOOK:
      content = <ConnectFacebook facebookLoginButton={facebookLoginButton} />;
      break;
    default:
      break;
  }

  if (isFacebookConnected) {
    content = (
      <FacebookAccountSelectionComponent
        onSuccess={onFacebookConnected}
        setStep={setStep}
        onError={onError}
        facebookLoginButton={facebookLoginButton}
      />
    );
  }

  if (!user || isLoading) {
    content = <Loading />;
  }

  return (
    <div className="w-full">
      <div className="mx-6 flex flex-col">
        <div>
          <h2 className="text-3xl font-extrabold text-gray-900">
            Connect your Instagram
          </h2>
        </div>
        <div className="w-full">
          {errorMessage ? <div className="mb-4">{errorMessage}</div> : null}
          <div className="flex min-h-72 w-full items-center">
            <div className="w-full">{content}</div>
          </div>
          <StepsComponent currentStep={step} setStep={setStep} />
        </div>
      </div>
    </div>
  );
};

export default FacebookConnectPage;
