import { useMutation } from "@apollo/client";
import firebase from "firebase/app";
import { useEffect, useState } from "react";
import { auth } from "../config/firebase";
import { CREATOR_LOGIN } from "../graphql/mutations";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Loading,
  SignUpForm,
  SignUpInputs,
  VerificationCodeForm,
} from "@kalecard/common";
import LoginForm from "../components/forms/LoginForm";
import { logView } from "@kalecard/common";
import { localStorageService, LocalStorageKey } from "@kalecard/common";
import SignupFooter from "./SignupFooter";
import { onCodeSubmit, onPhoneSubmit, SignUpState } from "../utils/signup";

declare global {
  interface Window {
    recaptchaVerifier: any;
    confirmationResult: any;
  }
}

window.recaptchaVerifier = window.recaptchaVerifier || {};

interface SignUpProps {
  isSignIn: boolean;
  authenticated: boolean;
  signUpTitle?: string;
}

const SignUp = ({ isSignIn, authenticated, signUpTitle }: SignUpProps) => {
  const [state, setState] = useState(
    isSignIn ? SignUpState.LOGIN : SignUpState.SIGNUP
  );
  const [formData, setFormData] = useState(null);
  const [error, setError] = useState<string>(null);

  const locationState: any = useLocation().state;
  const history = useHistory();

  const [creatorLogin] = useMutation(CREATOR_LOGIN);

  const params = useParams();

  const recaptchaId = "recaptcha-container";

  useEffect(() => {
    const referralGroupDealId = localStorageService.getItem(
      LocalStorageKey.REFERRAL_GROUP_DEAL_ID
    );
    logView("SignUp", {
      referral_group_deal_id: referralGroupDealId,
      referral_code: params["referralCode"],
    });
    setState(isSignIn ? SignUpState.LOGIN : SignUpState.SIGNUP);
  }, [isSignIn]);

  useEffect(() => {
    if (params["referralCode"]) {
      localStorageService.setItem(
        LocalStorageKey.REFERRAL_CODE,
        params["referralCode"]
      );
    }
  }, [params]);

  useEffect(() => {
    if (authenticated) {
      if (locationState && locationState.from) {
        history.push(locationState.from.pathname);
      } else {
        history.push("/");
      }
    } else {
      auth.useDeviceLanguage();
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        recaptchaId,
        {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            console.log("Captcha callback", response);
            // onSignInSubmit();
          },
        }
      );
    }
    return () => { };
  }, [authenticated]);

  let content = null;
  switch (state) {
    case SignUpState.SIGNUP:
      content = (
        <>
          <SignUpForm
            submitForm={(data: SignUpInputs) =>
              onPhoneSubmit(
                SignUpState.SIGNUP,
                data,
                setState,
                setFormData,
                setError
              )
            }
          />
          <SignupFooter isLogin={false} />
        </>
      );
      break;
    case SignUpState.LOGIN:
      content = (
        <>
          <LoginForm
            submitForm={(data: SignUpInputs) =>
              onPhoneSubmit(
                SignUpState.LOGIN,
                data,
                setState,
                setFormData,
                setError
              )
            }
          />
          <SignupFooter isLogin={true} />
        </>
      );
      break;
    case SignUpState.VERIFICATION:
      content = (
        <VerificationCodeForm
          submitForm={(data) =>
            onCodeSubmit(data, formData, setState, setError, creatorLogin)
          }
        />
      );
      break;
    case SignUpState.LOADING:
      content = <Loading />;
      break;
    default:
      break;
  }

  if (authenticated) {
    return <Loading />;
  } else {
    return (
      <>
        <div>
          <h2 className="text-2xl font-extrabold text-kale-green-500">
            {isSignIn
              ? "Sign in to your account"
              : signUpTitle
                ? signUpTitle
                : "Create your account"}
          </h2>
          {error && <div className="font-bold text-kale-pink-500">{error}</div>}
        </div>
        <div id={recaptchaId}></div>
        <div className="mt-4">
          <div className="">{content}</div>
        </div>
      </>
    );
  }
};
export default SignUp;
