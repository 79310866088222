import { useMutation } from "@apollo/client";
import { Loading } from "@kalecard/common";
import { useState } from "react";
import { useHistory } from "react-router";
import { CHOOSE_CREATOR_FACEBOOK_ACCOUNTS } from "../graphql/mutations";

export interface FacebookAccountProps {
  id: string;
  name: string;
  imageUrl: string;
}

const FacebookAccount = ({ id, name, imageUrl }: FacebookAccountProps) => {
  const [loading, setLoading] = useState(false);
  const [chooseFacebookAccounts] = useMutation(
    CHOOSE_CREATOR_FACEBOOK_ACCOUNTS
  );
  const history = useHistory();

  const selectFacebookAccount = async () => {
    setLoading(true);
    try {
      await chooseFacebookAccounts({
        variables: {
          facebookIds: [id],
        },
      });
      history.replace("/instagram");
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  return (
    <div className="flex">
      <button
        className="inline-flex items-center justify-center rounded-full border border-transparent bg-kale-orange-400 px-6 py-2 text-center text-base text-kale-green-1000 shadow hover:bg-kale-orange-300 disabled:bg-kale-orange-300"
        onClick={() => selectFacebookAccount()}
        disabled={loading}
      >
        <img
          className="mr-1 h-6 w-6"
          src={imageUrl}
          alt={"image for " + name}
        />
        <p className="self-center text-lg lowercase">{name}</p>
      </button>
      {loading && (
        <span className="ml-2 h-6 w-6">
          <Loading margin="m-0" />
        </span>
      )}
    </div>
  );
};

export default FacebookAccount;
