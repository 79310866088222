import { Loading } from "@kalecard/common";
import { useEffect } from "react";
import useLocationQuery from "../hooks/useLocationQuery";
import { useParams } from "react-router";

export default function StripeConnectLinkPage() {
  const query = useLocationQuery();
  const params = useParams();
  useEffect(() => {
    console.log(query);
    if (params["type"] === "success") {
      window.location.href = `kale://connect/stripe`;
    } else {
      window.location.href = `kale://connect/stripe?refresh=true`;
    }
  });

  return (
    <>
      <Loading />
    </>
  );
}
