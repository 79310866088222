import { useEffect } from "react";
import FacebookOnboardingStep from "../../../enums/FacebookOnboardingStep";
import { logView } from "@kalecard/common";

export default function LinkFacebookPageSteps({ setStep, buttonClassName }) {
  useEffect(() => {
    logView("facebook_connect", "LinkFacebookPageSteps");
  });

  return (
    <div className="flex flex-col">
      <p className="pt-4">
        Don't worry. You can make this page and then forget about it.
      </p>
      <p className="pt-4">
        To add the Facebook Page that's connected to your Instagram business
        account:
      </p>
      <p className="pt-4">1. On the Instagram app. Go to your profile.</p>
      <p>
        2. Select <span className="font-bold">Edit Profile</span>.
      </p>
      <p>
        3. Under Public Business Information, Select{" "}
        <span className="font-bold">Page</span>.
      </p>
      <p>
        4. Choose a Page from your Pages that you'd like to connect to or select{" "}
        <span className="font-bold">Create a New Facebook Page</span>. This page
        is just for Kale. It won’t be publicized to your network.
      </p>
      <div className="flex items-center justify-center pt-8">
        <button
          onClick={() => {
            setStep(FacebookOnboardingStep.CONNECT_TO_FACEBOOK);
          }}
          type="button"
          className={buttonClassName}
        >
          Done
        </button>
      </div>
      <div className="pt-4 text-sm">
        Detailed instructions:{" "}
        <a
          href="https://help.instagram.com/570895513091465"
          className="text-blue-500 hover:text-blue-800"
          target="_blank"
          rel="noopener"
        >
          https://help.instagram.com/570895513091465
        </a>
      </div>
    </div>
  );
}
