enum FacebookOnboardingStep {
  SWITCH_CREATOR_ACCOUNT = "switch-creator-account",
  SWITCH_CREATOR_ACCOUNT_STEPS = "switch-creator-account-steps",
  LINK_FACEBOOK_PAGE = "link-facebook-page",
  LINK_FACEBOOK_PAGE_STEPS = "link-facebook-page-steps",
  CONNECT_TO_FACEBOOK = "connect-to-facebook",
  SWITCH_CREATOR_ACCOUNT_CHECK = "switch-creator-account-check",
  LINK_FACEBOOK_PAGE_CHECK = "link-facebook-page-check",
  FACEBOOK_SCREENSHOTS = "facebook-screenshots",
}

export default FacebookOnboardingStep;
