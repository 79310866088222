import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { Loading } from "@kalecard/common";
import { UserContext } from "../providers/UserProvider";
import { localStorageService, LocalStorageKey } from "@kalecard/common";
import { Link } from "react-router-dom";
import AppBanner from "../components/AppBanner";

export default function SkeletonPage(props) {
  const { user } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    const fromLocation = localStorageService.getItem(
      LocalStorageKey.AUTH_FROM_LOCATION
    );

    if (fromLocation) {
      localStorageService.removeItem(LocalStorageKey.AUTH_FROM_LOCATION);
      history.push(fromLocation);
    }
  }, [user]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kale</title>
        <meta property="og:title" content="Kale" />
        <meta
          property="og:description"
          content="Earn rewards for talking about your favorite brands."
        />
        <meta property="og:type" content="website" />
      </Helmet>
      {user && (
        <div className="flex flex-col">
          <div className="mx-auto h-auto min-h-screen w-full max-w-lg sm:border">
            <section
              id="bottom-navigation"
              className="fixed inset-x-0 top-0 z-10 mx-auto block max-w-lg border-b bg-white shadow"
            >
              <AppBanner />
              <div className="flex justify-between p-4">
                <Link to="/">
                  <img
                    className="h-6"
                    src="https://storage.googleapis.com/kale-public/kale-app/kale-lockup.svg"
                  />
                </Link>
              </div>
            </section>
            <div className="min-h-screen py-20">
              {user ? (
                props.children
              ) : (
                <div className="min-h-screen items-center justify-center pt-24">
                  <Loading />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
