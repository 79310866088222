import Footer from "../components/Footer";
import KaleLogo from "../images/kale-logo.svg";

export default function OnboardingSkeleton(props) {
  return (
    <>
      <div className="flex min-h-[90vh] w-full items-center justify-center bg-white pb-20 sm:mb-0">
        <div className="flex flex-1 flex-col justify-between px-4 pt-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div className="mb-4 flex w-full justify-center">
              <img
                className="h-16 w-16 rounded-full border-kale-green-500 bg-kale-mint-500 p-1"
                src={KaleLogo}
                alt="Kale"
              />
            </div>
            {props.children}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
