import gql from "graphql-tag";
export const UPDATE_CREATOR_FACEBOOK_ACCESS_TOKEN = gql`
  mutation UpdateCreatorFacebookAccessToken(
    $accessToken: String!
    $scopes: String!
    $domain: String!
  ) {
    updateCreatorFacebookAccessToken(
      accessToken: $accessToken
      scopes: $scopes
      domain: $domain
    )
  }
`;
export const CHOOSE_CREATOR_FACEBOOK_ACCOUNTS = gql`
  mutation ChooseCreatorFacebookAccounts($facebookIds: [ID]) {
    chooseCreatorFacebookAccounts(facebookIds: $facebookIds)
  }
`;
export const PLAID_CREATE_LINK_TOKEN = gql`
  mutation PlaidCreateLinkToken {
    plaidCreateLinkToken
  }
`;

export const CREATOR_LOGIN = gql`
  mutation CreatorLogin(
    $name: String
    $socialHandle: String
    $referralCode: String
    $email: String
    $referralGroupDealId: String
    $isTikTokHandle: Boolean
  ) {
    creatorLogin(
      name: $name
      socialHandle: $socialHandle
      referralCode: $referralCode
      email: $email
      referralGroupDealId: $referralGroupDealId
      isTikTokHandle: $isTikTokHandle
    )
  }
`;

export const PLAID_EXCHANGE_PUBLIC_TOKEN = gql`
  mutation PlaidExchangePublicToken(
    $publicToken: String!
    $institutionId: String
  ) {
    plaidExchangePublicToken(
      publicToken: $publicToken
      institutionId: $institutionId
    )
  }
`;
export const CREATOR_SESSION = gql`
  mutation CreatorSession {
    creatorSession
  }
`;
export const OAUTH_TIKTOK = gql`
  mutation OauthTiktok($code: String!, $scopes: String!, $appId: String) {
    oauthTiktok(code: $code, scopes: $scopes, appId: $appId)
  }
`;
