import { Disclosure } from "@headlessui/react";
import { useState } from "react";
import { classNames } from "../utils/style";
import Underline from "../images/assets/underline.svg";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";

export default function PlaidFaqs({ isGroupDeal }: { isGroupDeal?: boolean }) {
  const [faqs, setFaqs] = useState(isGroupDeal ? groupBuyingFaqs : creatorFaqs);
  return (
    <div className="mx-6 mt-10 sm:mt-20">
      <div className="relative ">
        <img className="absolute -bottom-2 left-0" src={Underline} />
        <div className="absolute bottom-0 left-0 z-50 w-full ">
          <p className="text-center text-xl font-bold text-kale-green-500 sm:text-left sm:text-4xl ">
            Frequently asked questions
          </p>
        </div>
      </div>
      <div className="w-full">
        <dl className="">
          {faqs.map((faq, index) => (
            <Disclosure
              as="div"
              defaultOpen={false}
              key={index}
              className="pt-2"
            >
              {({ open }) => (
                <>
                  <dt className="mt-2 sm:mt-5">
                    <Disclosure.Button className="flex w-full items-center justify-between text-left text-gray-400">
                      <span className="text-lg font-medium text-kale-green-1000 sm:text-2xl">
                        {faq.question}
                      </span>
                      <span className="ml-6 flex h-7 items-center">
                        <ChevronUpDownIcon
                          className={classNames(
                            open ? "-rotate-180" : "rotate-0",
                            "h-6 w-6 transform"
                          )}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="pr-6">
                    <p className="text-base text-kale-green-400 sm:text-lg">
                      {faq.answer}
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  );
}

const creatorFaqs = [
  {
    question: (
      <p>
        Why{" "}
        <span className="font-bold text-kale-orange-500">should I link</span> my
        credit card?
      </p>
    ),
    answer:
      "Our system can only match you with brands that you've purchased from. Our algorithm scans your transactions to identify relevant brands and calculate cashback offers.",
  },
  {
    question: (
      <p>
        How does Kale link my{" "}
        <span className="font-bold text-kale-orange-500">credit card?</span>
      </p>
    ),
    answer: (
      <p>
        Your credit card is linked through the secure financial tech company,{" "}
        <a
          href="https://plaid.com/how-it-works-for-consumers/"
          target="_blank"
          rel="noopener"
          className="font-semibold text-kale-green-500 hover:text-kale-green-400"
        >
          Plaid
        </a>
        , which is used for Venmo, American Express, and many other reputable
        financial institutions.
      </p>
    ),
  },
  {
    question: (
      <p>
        Can I <span className="font-bold text-kale-orange-500">trust</span>{" "}
        Kale?
      </p>
    ),
    answer: (
      <p>
        Yes! Kale is a startup{" "}
        <a
          href="https://techcrunch.com/2021/10/15/check-out-these-startups-from-pears-demo-day-theres-usually-a-breakout-or-two-in-the-bunch/"
          target="_blank"
          rel="noopener"
          className="font-semibold text-kale-green-500 hover:text-kale-green-400"
        >
          funded by Silicon Valley.
        </a>{" "}
        We're on a mission to transform your social capital into economic value.
      </p>
    ),
  },
];

const groupBuyingFaqs = [
  {
    question: "What is a group deal?",
    answer:
      "Group deals are when friends buy together in order to save together.",
  },
  {
    question: "How do I get paid?",
    answer: "Kale pays you via Venmo. Simple as that!",
  },
  {
    question: "Why does Kale need my credit card?",
    answer:
      "Kale's algorithm scans your recent transactions to confirm your purchase and count it towards the group deal. Our top priority is keeping your data private and safe with bank-level security.",
  },
  {
    question: "How does Kale link your credit card?",
    answer: (
      <p>
        Your credit card is linked through the secure financial tech company,{" "}
        <a
          href="https://plaid.com/how-it-works-for-consumers/"
          target="_blank"
          rel="noopener"
          className="font-semibold text-kale-green-500 hover:text-kale-green-400"
        >
          Plaid
        </a>
        , which is used for Venmo, American Express, and many other reputable
        financial institutions.
      </p>
    ),
  },
  {
    question: "Can I trust Kale?",
    answer: (
      <p>
        Yes! Kale is a startup{" "}
        <a
          href="https://techcrunch.com/2021/10/15/check-out-these-startups-from-pears-demo-day-theres-usually-a-breakout-or-two-in-the-bunch/"
          target="_blank"
          rel="noopener"
          className="font-semibold text-kale-green-500 hover:text-kale-green-400"
        >
          funded by Silicon Valley.
        </a>{" "}
        We're on a mission to harness the power of peer to peer recommendations
        through honest product reviews.
      </p>
    ),
  },
];
