import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PlaidLink from "../components/PlaidLink";
import PlaidOAuthLink from "../components/PlaidOAuthLink";
import { UserContext } from "../providers/UserProvider";
import { localStorageService, LocalStorageKey } from "@kalecard/common";
import { logView } from "@kalecard/common";
import PlaidFaqs from "../components/PlaidFaqs";

interface PlaidConnectPageInterface {
  isOAuth: boolean;
}

export default function PlaidConnectPage({
  isOAuth,
}: PlaidConnectPageInterface) {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [title, setTitle] = useState<string>(null);
  const [isGroupDeal, setIsGroupDeal] = useState<boolean>(false);
  const [description, setDescription] = useState<string>(null);

  const onPlaidConnected = async () => {
    history.push("/");
  };

  useEffect(() => {
    const referralGroupDealId = localStorageService.getItem(
      LocalStorageKey.REFERRAL_GROUP_DEAL_ID
    );
    const isGroupBuyingSignup =
      localStorageService.getBoolean(
        LocalStorageKey.SETTING_IS_GROUP_BUYING_SIGNUP
      ) || referralGroupDealId != null;
    setIsGroupDeal(isGroupBuyingSignup);
    setTitle(
      isGroupBuyingSignup ? "Participate in group deal" : "Match with a brand"
    );
    setDescription(
      isGroupBuyingSignup
        ? "To reward you for participating in this group deal, Kale needs to verify your purchase."
        : "You'll get a text when you match with a brand you just shopped at 📲"
    );
  }, []);

  useEffect(() => {
    const referralGroupDealId = localStorageService.getItem(
      LocalStorageKey.REFERRAL_GROUP_DEAL_ID
    );
    const isGroupBuyingSignup =
      localStorageService.getBoolean(
        LocalStorageKey.SETTING_IS_GROUP_BUYING_SIGNUP
      ) || referralGroupDealId != null;
    if (user) {
      logView(null, {
        is_group_deal_signup: isGroupBuyingSignup,
        referral_group_deal_id: referralGroupDealId,
      });
    }
  }, [user]);

  var content = null;
  if (!isOAuth) {
    content = <PlaidLink onSuccess={onPlaidConnected} />;
  } else {
    content = <PlaidOAuthLink />;
  }

  if (isOAuth) {
    return (
      <>
        <div className="flex w-full flex-col justify-center pt-5">
          {content}
        </div>
      </>
    );
  }

  return (
    <div className="flex w-full flex-col">
      <div className="mx-6">
        <div className="flex flex-col">
          <h2 className="text-center text-3xl font-extrabold text-kale-green-500 sm:text-4xl">
            {title}
          </h2>
          <p className="text-center text-kale-green-500">{description}</p>
          <div className="mt-4 flex w-full flex-col items-center justify-center text-center">
            <div className="w-full sm:w-1/3">{content}</div>
            <p className="mt-2 text-center text-xs font-bold text-gray-500 sm:w-1/3">
              Kale is free for creators. We use bank-level security to keep your
              account information safe.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-10 sm:mt-36 sm:mb-10 sm:min-h-screen">
        <PlaidFaqs isGroupDeal={isGroupDeal} />
      </div>
    </div>
  );
}
