import { useContext, useState, useEffect } from "react";
import KaleLogo from "../images/kale-logo.svg";
import { UserContext } from "../providers/UserProvider";
import { useLazyQuery } from "@apollo/client";
import {
  BRAND_LANDING_PAGE,
  BrandLandingPageDataQuery,
  GET_CREATOR_SETTINGS,
} from "../graphql/queries";
import { Link, NavLink } from "react-router-dom";

interface PartnerBrand {
  brandName: string;
  logo: {
    alt: string;
    src: string;
  };
}

export default function LandingPage() {
  const { creator } = useContext(UserContext);
  const [partnerContent, setPartnerContent] = useState<PartnerBrand>(null);
  const [getBrandLandingPage] =
    useLazyQuery<BrandLandingPageDataQuery>(BRAND_LANDING_PAGE);

  useEffect(() => {
    const loadData = async () => {
      if (creator) {
        getBrandLandingPage({
          variables: {
            referralCode: creator?.referralCode,
          },
        }).then((result) => {
          let newPartnerContent = null;
          if (result.data.brandLandingPage) {
            newPartnerContent = {
              logo: {
                src: result.data.brandLandingPage?.brand?.logoUrl,
                alt: `${result.data.brandLandingPage?.brand?.name} logo`,
              },
              brandName: result.data.brandLandingPage?.brand?.name,
            };
          }
          setPartnerContent(newPartnerContent);
        });
      }
    };
    loadData();
  }, [creator]);

  const signOutLink = (
    <NavLink
      className="text-green-950 font-['DM Sans'] text-center text-xl font-bold leading-loose underline sm:text-sm sm:font-medium"
      to="/signout"
    >
      Sign out
    </NavLink>
  );

  return (
    <div className="h-full min-h-screen bg-kale-mint-500">
      <div className="w-full sm:mx-auto sm:max-w-5xl">
        {/* Header */}
        <div className="hidden w-full justify-between sm:flex sm:pt-6">
          <div>
            <img
              alt={"Kale Logo"}
              className="h-8"
              src="https://storage.googleapis.com/kale-public/kale-app/kale-lockup.svg"
            />
          </div>
          <div className="flex flex-row space-x-4">
            {/* Instagram Link */}
            <div className="flex items-center">
              <NavLink
                className="text-green-950 font-['DM Sans'] text-center text-xl font-bold leading-loose underline sm:text-sm sm:font-medium"
                to="/instagram"
              >
                Instagram
              </NavLink>
            </div>
            <div className="flex items-center">{signOutLink}</div>
          </div>
        </div>
        {/* Hero */}
        <div className="flex w-full sm:justify-center sm:pt-10">
          {/* Phone screenshots */}
          <div className="hidden"></div>
          {/* Download the app */}
          <div className="flex w-full flex-col items-center px-3 pt-20 pb-6 sm:w-96 sm:rounded-md sm:border sm:border-black sm:border-opacity-20 sm:bg-white sm:p-10">
            {partnerContent && partnerContent?.brandName !== "" ? (
              <div className="flex">
                <div className="h-20 w-20 rounded-full bg-kale-mint-200 sm:h-16 sm:w-16 sm:bg-kale-mint-400">
                  <img className="" src={KaleLogo} alt="Kale" />
                </div>
                <div className="-ml-6 h-20 w-20 rounded-full bg-kale-mint-200 sm:h-16 sm:w-16">
                  <img
                    className="rounded-full"
                    src={partnerContent?.logo.src}
                    alt={partnerContent?.logo.alt}
                  />
                </div>
              </div>
            ) : (
              <div>
                <img
                  className="h-20 w-20 rounded-full bg-kale-mint-200 sm:h-16 sm:w-16 sm:bg-kale-mint-400"
                  src={KaleLogo}
                  alt="Kale"
                />
              </div>
            )}
            {/* Name */}
            <div className="w-full pt-6 text-center text-4xl font-bold text-kale-green-500 sm:text-2xl">
              {creator ? <div>Hey {creator.name}!</div> : <div>Hey there!</div>}
            </div>
            {/* QR Code */}
            <div className="z-10 mt-12 rounded-xl border border-black border-opacity-10 bg-white p-6 sm:mt-6">
              <img
                src="https://storage.googleapis.com/kale-public/kale-app/kale-qr-code.png"
                className="h-40 w-40 sm:h-32 sm:w-32"
              />
            </div>
            {/* Card */}
            <div className="-mt-28 flex flex-col items-center space-y-7 rounded-lg bg-white pb-9 pt-32 sm:-mt-24 sm:pb-0">
              {/* Call to action */}
              <div className="px-8 text-center text-2xl sm:px-0 sm:text-base">
                Earn rewards for talking about your favorite brands.{" "}
                <span className="font-bold">Download the Kale app</span> using
                the links below.
              </div>
              {/* Links */}
              <div className="flex w-full justify-between px-6 sm:px-4">
                {/* Apple Link */}
                <div>
                  <a href="https://apps.apple.com/us/app/kale-creator-rewards/id1672660475">
                    <img
                      src="https://storage.googleapis.com/kale-public/kale-app/apple.png"
                      className="h-12 sm:h-10"
                    />
                  </a>
                </div>
                {/* Android Link */}
                <div>
                  <a href="https://play.google.com/store/apps/details?id=com.kalecard.kale&hl=en_US">
                    <img
                      src="https://storage.googleapis.com/kale-public/kale-app/google-play.png"
                      className="h-12 sm:h-10"
                    />
                  </a>
                </div>
              </div>
            </div>
            {/* Sign out */}
            <div className="pt-6 sm:hidden">{signOutLink}</div>
            {/* Instagram Link */}
            <div className="pt-6 sm:hidden">
              <NavLink
                className="text-green-950 font-['DM Sans'] text-center text-xl font-bold leading-loose underline sm:text-sm sm:font-medium"
                to="/instagram"
              >
                Instagram
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
