import { useEffect } from "react";
import FacebookPopupOne from "../../../images/fb-popup-1.png";
import FacebookPopupTwo from "../../../images/fb-popup-2.png";
import FacebookPopupThree from "../../../images/fb-popup-3.png";
import { logView } from "@kalecard/common";

export default function FacebookScreenshots({ facebookLoginButton }) {
  useEffect(() => {
    logView("facebook_connect", "FacebookScreenshots");
  });

  return (
    <>
      <div className="flex flex-col">
        <p className="pt-4 text-lg font-bold">
          Oops! Look like you still need to complete a step 🚧
        </p>
        <p className="">
          Select the correct Instagram and Facebook accounts under{" "}
          <span className="font-bold">Edit Settings</span> in the Facebook pop
          up. Here is how:
        </p>
        <div className="pt-4">
          <p>Click Edit Settings</p>
          <img
            className="rounded border-2 border-solid border-kale-green-500"
            src={FacebookPopupOne}
            alt="Click Edit Settings Facebook Popup Screenshot"
          />
        </div>
        <div className="pt-4">
          <p>Select the Instagram Accounts</p>
          <img
            className="rounded border-2 border-solid border-kale-green-500"
            src={FacebookPopupTwo}
            alt="Select the Instagram Accounts Facebook Popup Screenshot"
          />
        </div>
        <div className="pt-4">
          <p>Select the Facebook Pages</p>
          <img
            className="rounded border-2 border-solid border-kale-green-500"
            src={FacebookPopupThree}
            alt="Select the Facebook Pages Facebook Popup Screenshot"
          />
        </div>
        <div className="flex justify-center pt-8">{facebookLoginButton}</div>
        <div className="flex flex-col pt-8">
          <p>
            If you are still experiencing issues, text{" "}
            <span className="font-bold">HELP</span> to the Kale number:{" "}
            <span>
              <a
                className="text-blue-500 hover:text-blue-800"
                href="tel:+15154171709"
              >
                (515) 417-1709
              </a>
            </span>
          </p>
        </div>
      </div>
    </>
  );
}
