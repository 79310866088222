import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

export type LoginInputs = {
  phoneNumber: string;
};

interface LoginFormProps {
  submitForm: (data: LoginInputs) => Promise<void>;
}

export default function LoginForm({ submitForm }: LoginFormProps) {
  const { register, handleSubmit } = useForm<LoginInputs>();
  const locationState: any = useLocation().state;

  const submitLoginForm = (data: LoginInputs) => {
    submitForm(data);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kale | Login</title>
        <meta property="og:title" content="Kale | Login" />
        <meta property="og:description" content="Login to Kale" />
        <meta property="og:type" content="website" />
      </Helmet>
      <form onSubmit={handleSubmit(submitLoginForm)}>
        <div>
          <div className="mt-1 flex rounded-md shadow-sm">
            <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500">
              +1
            </span>
            <input
              placeholder="Phone number"
              id="phoneNumber"
              name="phoneNumber"
              type="tel"
              {...register("phoneNumber", { required: true })}
              className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-kale-orange-500 focus:ring-kale-orange-500"
            />
          </div>
        </div>
        <div className="mt-4">
          <button
            type="submit"
            className="flex w-full justify-center rounded-3xl border border-kale-green-500 bg-kale-orange-400 py-2 px-4 text-base font-medium text-kale-green-500 shadow-sm hover:bg-kale-orange-300 focus:outline-none focus:ring-2 focus:ring-kale-orange-500 focus:ring-offset-2"
          >
            Login
          </button>
        </div>
      </form>
    </>
  );
}
