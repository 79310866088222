import { useEffect } from "react";
import FacebookOnboardingStep from "../../../enums/FacebookOnboardingStep";
import { logView } from "@kalecard/common";

export default function SwitchCreatorAccount({ setStep, buttonClassName }) {
  useEffect(() => {
    logView("facebook_connect", "SwitchCreatorAccount");
  });

  return (
    <>
      <div className="">
        You earn rewards when you <span className="font-bold">@mention</span>{" "}
        the brands you shop at 🛍️
      </div>
      <p className="pt-4 font-bold">
        First off, is your Instagram account a creator or business account?{" "}
      </p>
      <p className="text-sm text-gray-600">
        This lets Kale verify when you mention a brand.
      </p>
      <div className="mt-2 flex flex-col items-center space-y-2">
        <button
          onClick={() => {
            setStep(FacebookOnboardingStep.LINK_FACEBOOK_PAGE);
          }}
          type="button"
          className={buttonClassName}
        >
          Yes
        </button>
        <button
          onClick={() => {
            setStep(FacebookOnboardingStep.SWITCH_CREATOR_ACCOUNT_STEPS);
          }}
          type="button"
          className={buttonClassName}
        >
          No
        </button>
        <button
          onClick={() => {
            setStep(FacebookOnboardingStep.SWITCH_CREATOR_ACCOUNT_STEPS);
          }}
          type="button"
          className={buttonClassName}
        >
          I don't know
        </button>
      </div>
    </>
  );
}
