import { matchPath, NavLink, useHistory, useLocation } from "react-router-dom";
import SelectionComponent, {
  SelectionComponentItem,
} from "./SelectionComponent";

export default function InstagramTabsHeader({ id }) {
  const location = useLocation();
  const history = useHistory();

  const matchUrl = (path: string) => {
    const match = matchPath(location.pathname, path);
    return match !== null;
  };

  const tabs = [
    { id: "0", name: "Insights", path: `/instagram/${id}/insights` },
    // { id: "1", name: "Mentions", path: `/instagram/${id}/mentions` },
    // { id: "2", name: "Messages", path: `/instagram/${id}/messages` },
  ];

  function getCurrentTabId(): string {
    return tabs.find((tab) => matchUrl(tab.path))?.id;
  }

  function onTabSelected(item: SelectionComponentItem) {
    history.push(item.path);
  }

  return (
    <>
      <div className="pb-5 border-b border-gray-200 sm:pb-0">
        <div className="mt-3 sm:mt-4">
          <div className="sm:hidden">
            <SelectionComponent
              list={tabs}
              selectedId={getCurrentTabId()}
              onSelect={onTabSelected}
              defaultTitle="Choose an option"
              showImage={false}
            />
          </div>
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              {tabs.map((tab) => (
                <NavLink
                  exact
                  key={tab.name}
                  to={tab.path}
                  activeClassName="border-kale-green-500 text-kale-green-500 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                  className="border-transparent text-gray-500 hover:text-kale-green-500 hover:border-kale-green-500 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                  aria-current={matchUrl(tab.path) ? "page" : undefined}
                >
                  {tab.name}
                </NavLink>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
